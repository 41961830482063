// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lilita-one-regular {
    font-family: "Lilita One", sans-serif;
    font-weight: 400;
    font-style: normal;
}
  .lilita-one-regular-inst {
    font-family: "Protest Strike", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: red;
    font-size: xx-large;
  }
  .region{
    background-color: skyblue;
    border-radius: 10px;
    border: 3px solid white;
  }
  .lilita-one-regular-options {
    font-family: "Protest Strike", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .name{
    margin-top: 20px;
    padding-top: 20px;
    background-color: skyblue;
    border-radius: 10px;
    border: 3px solid white;
  }
  .input-name{
    background-color: rgb(62, 60, 58);
    color: rgb(255, 255, 255);
    border-radius:10px ;
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
  }
  .btn-primary{
    font-size: xx-large;
    margin-top: 10px;
  }

  .lilita-one-regular-submit {
    font-family: "Protest Strike", sans-serif;
    font-weight: 400;
    font-style: normal;
  }`, "",{"version":3,"sources":["webpack://./src/Quizuser.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,gBAAgB;IAChB,kBAAkB;AACtB;EACE;IACE,yCAAyC;IACzC,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;IACV,mBAAmB;EACrB;EACA;IACE,yBAAyB;IACzB,mBAAmB;IACnB,uBAAuB;EACzB;EACA;IACE,yCAAyC;IACzC,gBAAgB;IAChB,kBAAkB;EACpB;EACA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;IACnB,uBAAuB;EACzB;EACA;IACE,iCAAiC;IACjC,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,mBAAmB;EACrB;EACA;IACE,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,yCAAyC;IACzC,gBAAgB;IAChB,kBAAkB;EACpB","sourcesContent":[".lilita-one-regular {\n    font-family: \"Lilita One\", sans-serif;\n    font-weight: 400;\n    font-style: normal;\n}\n  .lilita-one-regular-inst {\n    font-family: \"Protest Strike\", sans-serif;\n    font-weight: 400;\n    font-style: normal;\n    color: red;\n    font-size: xx-large;\n  }\n  .region{\n    background-color: skyblue;\n    border-radius: 10px;\n    border: 3px solid white;\n  }\n  .lilita-one-regular-options {\n    font-family: \"Protest Strike\", sans-serif;\n    font-weight: 400;\n    font-style: normal;\n  }\n  .name{\n    margin-top: 20px;\n    padding-top: 20px;\n    background-color: skyblue;\n    border-radius: 10px;\n    border: 3px solid white;\n  }\n  .input-name{\n    background-color: rgb(62, 60, 58);\n    color: rgb(255, 255, 255);\n    border-radius:10px ;\n    width: 100%;\n    padding: 10px;\n    margin-bottom: 15px;\n  }\n  .btn-primary{\n    font-size: xx-large;\n    margin-top: 10px;\n  }\n\n  .lilita-one-regular-submit {\n    font-family: \"Protest Strike\", sans-serif;\n    font-weight: 400;\n    font-style: normal;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
