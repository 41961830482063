// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Overall page styling */
.games-page {
  background-color: #1a1a2e;
  min-height: 100vh; /* Ensure full-page height */
  display: flex;
  flex-direction: column;
}
.quiz-img {
  width: 100%;
}
.games-containing{
  width: 100%;
  margin-left: 5px;
}

/* Game image styling */
.game-img {
  width: 100%; /* Ensure the image fills the space properly */
  display: block;
  margin: 0px auto;
  border-radius: 8px;
  margin-top: 30px;
}
/* Adjusting images for small screens */
@media (max-width: 768px) {
  .game-img {
    width: 100%; /* Adjust image size on medium screens */
  }
}

@media (max-width: 480px) {
  .game-img {
    width: 85%; /* Adjust image size further on smaller screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/Games.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,yBAAyB;EACzB,iBAAiB,EAAE,4BAA4B;EAC/C,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA,uBAAuB;AACvB;EACE,WAAW,EAAE,8CAA8C;EAC3D,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;AACA,uCAAuC;AACvC;EACE;IACE,WAAW,EAAE,wCAAwC;EACvD;AACF;;AAEA;EACE;IACE,UAAU,EAAE,iDAAiD;EAC/D;AACF","sourcesContent":["/* Overall page styling */\n.games-page {\n  background-color: #1a1a2e;\n  min-height: 100vh; /* Ensure full-page height */\n  display: flex;\n  flex-direction: column;\n}\n.quiz-img {\n  width: 100%;\n}\n.games-containing{\n  width: 100%;\n  margin-left: 5px;\n}\n\n/* Game image styling */\n.game-img {\n  width: 100%; /* Ensure the image fills the space properly */\n  display: block;\n  margin: 0px auto;\n  border-radius: 8px;\n  margin-top: 30px;\n}\n/* Adjusting images for small screens */\n@media (max-width: 768px) {\n  .game-img {\n    width: 100%; /* Adjust image size on medium screens */\n  }\n}\n\n@media (max-width: 480px) {\n  .game-img {\n    width: 85%; /* Adjust image size further on smaller screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
