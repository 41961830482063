// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dailyrewardspaging {
    background-color: #131212;
    min-height: 100vh;
    color: white;
    

}
.rewards-contents{
    width: 100%;
    margin-top: 25px;
    margin-left: 5px;
}

.DailyRewards-img {
    width: 100%;
}

.dr-bg {
    background-color: #393744;
    height: 60px;
    border-radius: 10px;
}

.dr-bg:hover {
    background-color: green;
}

.rewards-bars {
    padding: 15px;
    margin-bottom: 10px;
    height: 120px;
}

.day-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: transform 0.3s;
}

.day-content.zoomed {
    transform: scale(1.1);
}

.coin-img {
    width: 30px;
    margin: 5px 0;
}

.container {
    padding: 0 10px; /* Prevents container from being too wide */
}
`, "",{"version":3,"sources":["webpack://./src/Dailyrewards.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;;;AAGhB;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,eAAe,EAAE,2CAA2C;AAChE","sourcesContent":[".dailyrewardspaging {\n    background-color: #131212;\n    min-height: 100vh;\n    color: white;\n    \n\n}\n.rewards-contents{\n    width: 100%;\n    margin-top: 25px;\n    margin-left: 5px;\n}\n\n.DailyRewards-img {\n    width: 100%;\n}\n\n.dr-bg {\n    background-color: #393744;\n    height: 60px;\n    border-radius: 10px;\n}\n\n.dr-bg:hover {\n    background-color: green;\n}\n\n.rewards-bars {\n    padding: 15px;\n    margin-bottom: 10px;\n    height: 120px;\n}\n\n.day-content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    transition: transform 0.3s;\n}\n\n.day-content.zoomed {\n    transform: scale(1.1);\n}\n\n.coin-img {\n    width: 30px;\n    margin: 5px 0;\n}\n\n.container {\n    padding: 0 10px; /* Prevents container from being too wide */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
