import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Dailyrewards.css';
import Dailyrewards from './assets/dailyrewards.png';
import Coin from './assets/Coin.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Navbar';

const DailyRewardsPage = () => {
    const navigate = useNavigate();
    const [zoomedDay, setZoomedDay] = useState(null);

    const handleExitClick = () => {
        console.log("clicked");
        navigate('/'); // Navigate to the homepage
    };

    const handleDayClick = (day) => {
        setZoomedDay(day);
    };

    return (
        <div className='dailyrewardspaging'>
            <div className="rewards-heading">
                <img src={Dailyrewards} alt="Daily Rewards" className='DailyRewards-img'/>
            </div>

            <div className="container ">
                <div className="row justify-content-center w-100 rewards-contents ">
                    {[...Array(12)].map((_, index) => {
                        const day = index + 1;
                        const amount = day * 50;
                        return (
                            <div className='col-3 dr-bg rewards-bars mx-2 my-2' key={day} onClick={() => handleDayClick(day)}>
                                <div className={`day-content ${zoomedDay === day ? 'zoomed' : ''}`}>
                                    <span>Day {day}</span>
                                    <img src={Coin} alt="Coin" className='coin-img'/>
                                    <span>{amount}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Navbar />
        </div>
    );
};

export default DailyRewardsPage;
