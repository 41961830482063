// App.js
import React from 'react';
import Navbar from './Navbar';
import Home from './Home';
import Games from './Games';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Setting from './Setting';
import DailyRewardsPage from './DailyRewardsPage';
import RewardsPage from './RewardsPage';
import Quizuser from './Quizuser';
import Quizgame from './Quizgame';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/games" element={<Games />} />
        <Route path="/settings" element={<Setting/>}/>
        <Route path="/dailyrewards" element={<DailyRewardsPage/>}/>
        <Route path="/rewards" element={<RewardsPage/>}/>
        <Route path="/user" element={<Quizuser/>}/>
        <Route path="/game" element={<Quizgame/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;