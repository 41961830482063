// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rewards-pager {
    background-color: #131212;
    min-height: 100vh;
    color: white;
  }
  .rewards-img{
    width: 100%;
  }
  .rd-bg{
    background-color: #393744;
    height: 60px;
    border-radius: 10px;
    padding: 10px 20px 10px 10px;
    margin-top: 10px;
  }
  .task-list{
    padding-top: 20px;
    padding-left: 25px;
  }
  .telegram-img{
    width: 40px;
    border-radius: 10px;
    margin-left: 0px;
  }
  .telegram-img2{
    width: 30px;
    border-radius: 10px;
    margin-left: 0px;
  }
  .text-telegram{
    color: white;
    font-size: 15px;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;

  }
  .rewards-list{
    display: flex;
    justify-content: center;
    align-items: center;

  }`, "",{"version":3,"sources":["webpack://./src/RewardsPage.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;EACd;EACA;IACE,WAAW;EACb;EACA;IACE,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,4BAA4B;IAC5B,gBAAgB;EAClB;EACA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;EACA;IACE,WAAW;IACX,mBAAmB;IACnB,gBAAgB;EAClB;EACA;IACE,WAAW;IACX,mBAAmB;IACnB,gBAAgB;EAClB;EACA;IACE,YAAY;IACZ,eAAe;IACf,SAAS;IACT,yCAAyC;;EAE3C;EACA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;EAErB","sourcesContent":[".rewards-pager {\n    background-color: #131212;\n    min-height: 100vh;\n    color: white;\n  }\n  .rewards-img{\n    width: 100%;\n  }\n  .rd-bg{\n    background-color: #393744;\n    height: 60px;\n    border-radius: 10px;\n    padding: 10px 20px 10px 10px;\n    margin-top: 10px;\n  }\n  .task-list{\n    padding-top: 20px;\n    padding-left: 25px;\n  }\n  .telegram-img{\n    width: 40px;\n    border-radius: 10px;\n    margin-left: 0px;\n  }\n  .telegram-img2{\n    width: 30px;\n    border-radius: 10px;\n    margin-left: 0px;\n  }\n  .text-telegram{\n    color: white;\n    font-size: 15px;\n    margin: 0;\n    font-family: Arial, Helvetica, sans-serif;\n\n  }\n  .rewards-list{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
