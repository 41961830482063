import React from 'react';
import './Games.css';
import Bestfriend from './assets/bestfriend.png';
import Brother from './assets/brotherquiz.png';
import Friend from './assets/friendquiz.png';
import Mother from './assets/motherquiz.png';
import Sister from './assets/sisterquiz.png';
import Father from './assets/fatherquiz.png';
import Quiz from './assets/Quiz.png';
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';

const Games = () => {
  const navigate = useNavigate()

  const handlequiz = ()=>{
    navigate('/user')

  }
  return (
    <div className="games-page">
      <div className="games-header">
        <img src={Quiz} alt="Quiz" className="quiz-img" />
      </div>
      <div className="container ">
        <div className="row games-containing">
          <div className="col-12 col-md-6 mb-3">
            <img src={Bestfriend} alt="Bestfriend" className="game-img" onClick={handlequiz} />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <img src={Friend} alt="Friend" className="game-img" />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <img src={Brother} alt="Brother" className="game-img" />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <img src={Sister} alt="Sister" className="game-img" />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <img src={Mother} alt="Mother" className="game-img" />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <img src={Father} alt="Father" className="game-img" />
          </div>
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default Games;
