// Navbar.js
import React from 'react';
import DailyRewardsIcon from './assets/Daily_Rewards.png';
import RewardsIcon from './assets/Rewards.png';
import HomeIcon from './assets/Home.png';
import PlayIcon from './assets/Play.png';
import SettingsIcon from './assets/Settings.png';
import RectangleBar from './assets/Rectanglebar.png';
import { Link } from 'react-router-dom';
import './Navbar.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Navbar = () => {
  return (
    <nav className="navbar fixed-bottom">
      <div className="container p-0">
        <div className="row justify-content-center align-items-center w-100 m-3  ">
          <div className="col-12 text-center p-0  position-relative">
           <img src={RectangleBar} alt="Rectangle Bar" className="rectangle-bar" /> 
            <div className="icons-container d-flex justify-content-around">
              <Link to="/games">
                <img src={PlayIcon} alt="Play" className="icon" />
              </Link>
              <Link to="/dailyrewards">
              <img src={DailyRewardsIcon} alt="Daily Rewards" className="icon" />
              </Link>
              <Link to="/">
                <img src={HomeIcon} alt="Home" className="icon" />
              </Link>
              <Link to="/rewards">
              <img src={RewardsIcon} alt="Rewards" className="icon" />
              </Link>
              <Link to="/settings">
                <img src={SettingsIcon} alt="Settings" className="icon" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
