// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  padding: 0;
  background-color: transparent;
  box-sizing: border-box;
}
.navbar .rectangle-bar {
  width: 100%;
  height: 80px;
  margin: 0 auto;
  padding: 0;
  background-size: cover;
  background-position: center;
}

.icons-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
}
.icon {
  width: 90px;
  height: 90px;
  cursor: pointer;
  transition: transform 0.2s;
}

.icon:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .icon {
      width: 40px;
      height: 40px;
  }
}

@media (max-width: 480px) {
  .icon {
      width: 70px;
      height: 70px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Navbar.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,6BAA6B;EAC7B,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,UAAU;EACV,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;MACI,WAAW;MACX,YAAY;EAChB;AACF;;AAEA;EACE;MACI,WAAW;MACX,YAAY;EAChB;AACF","sourcesContent":[".navbar {\n  padding: 0;\n  background-color: transparent;\n  box-sizing: border-box;\n}\n.navbar .rectangle-bar {\n  width: 100%;\n  height: 80px;\n  margin: 0 auto;\n  padding: 0;\n  background-size: cover;\n  background-position: center;\n}\n\n.icons-container {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 100%;\n  max-width: 600px;\n}\n.icon {\n  width: 90px;\n  height: 90px;\n  cursor: pointer;\n  transition: transform 0.2s;\n}\n\n.icon:hover {\n  transform: scale(1.1);\n}\n\n@media (max-width: 768px) {\n  .icon {\n      width: 40px;\n      height: 40px;\n  }\n}\n\n@media (max-width: 480px) {\n  .icon {\n      width: 70px;\n      height: 70px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
